//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

class Overview extends Component {
  componentDidMount() {
    Edotensei.add(Scripts);
  }

  render() {
    return (
      <div>
        {/** navbar */}
        <Navbar level={this.props.userLevel} />

        <div className="overview" id="dashboard">
          {/** sidebar */}
          <Sidebar level={this.props.userLevel} />

          <img id="overview-image-1" src="/images/overview/03.png" alt="" />

          <img id="overview-image-2" src="/images/overview/02.png" alt="" />

          <img id="overview-image-3" src="/images/overview/01.png" alt="" />

          <img id="overview-image-4" src="/images/overview/04.png" alt="" />

          {/** content / overview */}
          <div className="dashboard-content">
            <div className="row">
              <div id="overview-text" className="col-lg-12">
                <p className="overview-text-1">
                  Welcome {JSON.parse(localStorage.getItem("token")).displayName}
                </p>
                {/* <p className="overview-text-2">
                  Persiapkan dirimu untuk menjawab soal-soal <br /> yang ada di
                  Letstudy Education Training Center.
                  <br />
                  #WeCareAboutYourStudy
                </p> */}
                <a
                  href="/pilih-ujian"
                  style={{ paddingLeft: "80px", paddingRight: "80px" }}
                  className="button border"
                >
                  Mulai Ujian
                </a>
              </div>
              {/*
                <div className="col-lg-12" style={{ marginTop: "10%" }}>
                <p
                  style={{
                    color: "black",
                    fontSize: "5em",
                    lineHeight: "20px"
                  }}
                >
                  Welcome <br />
                  <br />
                  <br />
                  <br />
                  {JSON.parse(localStorage.getItem("token")).displayName}
                </p>
                <br />
                <p style={{ color: "black", fontSize: "1em" }}>
                  Persiapkan dirimu untuk menjawab soal-soal <br /> yang ada di
                  Letstudy Education Training Center.
                  <br />
                  #WeCareAboutYourStudy
                </p>
                {this.props.userLevel === 0 ? (
                  <a
                    href="/pilih-ujian"
                    style={{ paddingLeft: "80px", paddingRight: "80px" }}
                    className="button border"
                  >
                    Mulai Ujian
                  </a>
                ) : null}
                </div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Overview.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default Overview;
