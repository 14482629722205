//module
import React, { Component } from "react";
import Edotensei from "edotensei";
// import Slider from "react-slick";

//component
import Navbar from "./Include/Navbar";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

class landingPage extends Component {
  constructor(props) {
    super();

    this.state = {
      isAuth: "",
      hidden: true,
      testimoni: [
        {
          nama: "ANDI TENRI SANNA",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/03.png",
          deskripsi:
            "Terima Kasih Letstudy!! setelah bimbel di letstudy saya lebih termotivasi untuk giat belajar dan berlatih menyelesaikan soal apalagi dibimbing oleh para mentor yang humble. Alhamdulillah akhirnya saya LULUS!! Jadi teman-teman yang mau lebih mempermantap persiapan sebelum mengikuti ujian, YUK BIMBELNYA DI LETSTUDY!!"
        },
        {
          nama: "SUPARDI",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/01.png",
          deskripsi:
            "Buat teman-teman yang ingin persiapkan diri lolos CPNS maupun PPPK yang akan datang, mulai sekarang harus mulai identifikasi sumber-sumber yang bisa menunjang kemampuan teman-teman. Dulunya saya juga sama dengan teman-teman yang bingung mau belajar apa, dimana, tapi harganya juga terjangkau. Saya sarankan teman-teman ikut bimbingan belajar LETSTUDY . Belajar di Letstudy itu fokus, serius, tapi juga santai. Selain itu, tentornya juga asyik jadi materi yang dipelajari cepat dimengerti"
        },
        {
          nama: "A. FIRMAN ALI SAFAAT",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/02.png",
          deskripsi:
            "Buat Kamu yang mau jadi PNS tentu persiapan akan sangat banyak, melalui letstudy persiapan menuju CPNS akan sangat mudah. Yang tentunya didukung oleh para mentor berpengalaman, materi yang update dan fasilitas yang nyaman. Awal mendaftar CPNS pada tahun 2017 namun belum lolos karena persiapan memang tidak didukung oleh materi-materi yang update, belajar sendiri jadi lebih sulit dan diahun 2018 saya mencoba ikut program intensif letstudy dan Alhamdulillah bisa lolos."
        },
        {
          nama: "NURFATIMAH",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/04.png",
          deskripsi:
            "Kursus di LETSTUDY sangat menyenangkan, tentornya tidak kaku dalam mengajarkan materi-materi dan berkat itu ALHAMDULILLAH saya LULUS sesuai dengan harapan."
        },
        {
          nama: "NINIG FITRI ARIFA",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/05.png",
          deskripsi:
            "Tidak pernah terpikirkan sebelumnya saya bisa lulus PNS/ASN di usia muda karena persaingan sangat ketat, tapi dengan mengikuti BIMBINGAN INTENSIF CPNS LETSTUDY saya percaya diri dan ALHAMDULILLAH saya LULUS."
        },
        {
          nama: "PRA WARMANSYAH",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/06.png",
          deskripsi:
            "ALHAMDULILLAH bisa LULUS PNS 2018 berkat BIMBINGAN INTENSIF LETSTUDY dengan bantuan mentor-mentor yang memberikan TIPS dan TRIK mengerjakan SKD yang mudah dimengerti dan gampang direalisasikan pas ujian SKD yang sebenarnya."
        },
        {
          nama: "MUKHARRAMAH AZIS SENA",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/07.png",
          deskripsi:
            "Buat kalian yang mau mendaftar CPNS ataupun PPPK nanti, saya sarankan untuk mempersiapkan diri dengan matang, salah satunya dengan bimbel di LETSTUDY, karena disana kita pelajari soal yang bentuknya serupa dengan soal tes, terutama TIU yang notabene saya lemah disitu. Mentornya juga asyik banget diajak sharing dan cepat tanggap kalau ditanya via WhatsApp, tempatnya juga sangat nyaman. Terimakasih LETSTUDY! ALHAMDULILLAH saya lulus CPNS 2018"
        },
        {
          nama: "RONALD SMITH",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/08.png",
          deskripsi:
            "Awal mendapat kabar bahwa CPNS 2018 akan dibuka, saya segera mempersiapkan diri untuk menghadapinya karena jujur saya tidak tahu harus mulai darimana. Saya melihat LETSTUDY membuka BIMBINGAN INTENSIF CPNS dan saya mencoba bergabung disana. Ternyata mentor-mentornya menjelaskan materi dengan jelas dan mudah dimengerti. dan ALHAMDULILLAH inilah BUKTINYA saya LULUS PNS 2018"
        },
        {
          nama: "WIWIN WINARTI S",
          asal: "PESERTA PROGRAM INTENSIF PERSIAPAN CPNS LETSTUDY",
          poto: "/images/people/09.png",
          deskripsi: "Terimakasih kepada letstudy!! Berkat pemberian materinya yang fun dan latihan-latihan soalnya yang ringan tapi berbobot persis dengan soal yang saya dapat saat tes, sangat membuat saya percaya diri saat mengikuti tes CPNS dan alhamdulillah saya lulus"
        }
      ],

      //pagination
      currentPage: 1,
      todosPerPage: 1
    };
  }

  //dieksekusi ketika html selesai dirender
  componentDidMount() {
    //render script
    Edotensei.add(Scripts);
  }

  render() {
    const { auth } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true
    };

    //initial
    const { testimoni, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = testimoni.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(testimoni.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        {/** navbar  */}
        <Navbar auth={auth} />

        {/** benner */}
        <div id="home">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>
                  <b style={{ fontFamily: "poppins" }}>
                    Ujian <br /> Jauh Lebih <br /> Mudah dan <br /> Menyenangkan
                  </b>
                </h2>
                <h4 style={{ lineHeight: "30px", fontFamily: "poppins" }}>
                  Waktunya untuk kamu mewujudkan impian <br />
                  dan cita-cita!
                </h4>
                <h4 style={{ fontFamily: "poppins" }}>#WeCareAboutYourStudy</h4>
                <br />
                {auth ? (
                  <a
                    href="/pilih-ujian"
                    className="button"
                    style={{
                      padding: "5px 40px",
                      backgroundColor: "#cf1f49",
                      fontFamily: "poppins"
                    }}
                  >
                    MULAI
                  </a>
                ) : (
                  <a
                    href="#sign-in-dialog"
                    className="button popup-with-zoom-anim"
                    style={{
                      padding: "5px 40px",
                      backgroundColor: "#cf1f49",
                      fontFamily: "poppins"
                    }}
                  >
                    MULAI
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        {/** Tentang kami */}
        <div id="tentang-kami">
          <div className="container">
            <div className="row">
              <div className="col-md-12" align="right">
                <h2>
                  <b style={{ fontFamily: "poppins" }}>Tentang Kami</b>
                </h2>
                <h4 style={{ fontFamily: "poppins" }}>
                  <b style={{ fontFamily: "poppins" }}>Letstudy</b> merupakan
                  lembaga pendidikan <br /> non formal yang berfokus pada
                  bimbingan <br /> dan pelatihan untuk peserta didik yang <br />
                  memiliki motto "Young - Future - Smart".
                </h4>
                <br />
                <a
                  href="#visi-misi"
                  onClick={e => {
                    this.setState({ hidden: false });
                  }}
                  className="button border"
                  style={{
                    padding: "5px 40px",
                    borderColor: "#cf1f49",
                    color: "#cf1f49",
                    fontFamily: "poppins"
                  }}
                >
                  PELAJARI
                </a>
              </div>
            </div>
          </div>
        </div>

        {/** Tentang kami */}
        <div hidden={this.state.hidden} id="visi-misi">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>
                  <b style={{ fontFamily: "poppins" }}>VISI</b>
                </h2>

                <h4 style={{ fontFamily: "poppins" }}>
                  Menjadi bimbingan belajar dengan mengutamakan kualitas dan
                  kuantitas yang berlandaskan kekeluargaan guna memberikan
                  pendampingan intensif untuk para peserta.
                </h4>
              </div>
              <div className="col-md-6">
                <h2>
                  <b style={{ fontFamily: "poppins" }}>MISI</b>
                </h2>

                <ul
                  style={{
                    textAlign: "justify",
                    textJustify: "inter-word",
                    fontFamily: "poppins"
                  }}
                >
                  <li>
                    <h4 style={{ fontFamily: "poppins" }}>
                      Memberikan pendampingan yang maksimal sesuai dengan
                      perkembangan teknologi yang ada.
                    </h4>
                  </li>

                  <li>
                    <h4 style={{ fontFamily: "poppins" }}>
                      Memberikan program-program intensif yang dapat memudahkan
                      peserta selama mengikuti bimbingan.
                    </h4>
                  </li>
                  <li>
                    <h4 style={{ fontFamily: "poppins" }}>
                      Memberikan pelatihan terbaik guna penyerapan materi yang
                      efektif untuk para peserta.
                    </h4>
                  </li>
                  <li>
                    <h4 style={{ fontFamily: "poppins" }}>
                      Fokus mengupdate perkembangan materi pelatihan.{" "}
                    </h4>
                  </li>
                  <li>
                    <h4 style={{ fontFamily: "poppins" }}>
                      Selalu berinovasi di dunia pendidikan guna mengikuti
                      perkembangan zaman.
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/** Testimoni */}
        <div id="testimoni">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>
                  <b style={{ fontFamily: "poppins" }}>
                    Apa kata temanmu tentang Letstudy?
                  </b>
                </h2>

                {currentTodos.map((item, i) => (
                  <div key={i} style={{ marginTop: "0" }} className="row">
                    <div className="testimoni-image-wrap col-md-4">
                      <img className="testimoni-image" src={item.poto} alt="" />
                      <br />
                      <br />
                      <center>
                        <h4>
                          <b style={{ fontFamily: "poppins" }}>{item.nama}</b>
                        </h4>
                      </center>
                    </div>

                    <div className="col-md-8">
                      <hr className="testimoni-hr" />
                      <h3 className="testimoni-kata-siswa">
                        <b style={{ fontFamily: "poppins" }}>Kata Siswa</b>
                      </h3>
                      <br />
                      <blockquote>
                        <h4
                          style={{
                            textAlign: "left",
                            color: "white",
                            lineHeight: "30px",
                            fontFamily: "poppins"
                          }}
                        >
                          {item.deskripsi}
                        </h4>
                      </blockquote>

                      {pageNumbers.map(number => (
                        <i
                          id={number}
                          key={number}
                          style={{
                            fontSize: "25px",
                            color: "white",
                            cursor: "pointer",
                            margin: "10px"
                          }}
                          className={
                            currentPage === number
                              ? "fa fa-dot-circle-o"
                              : "fa fa-circle-o"
                          }
                          onClick={e => {
                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/** footer */}
        <div id="footer" className="dark">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-6">
                <img className="footer-logo" src="images/logo-2.png" alt="" />
              </div>

              <div className="col-md-4  col-sm-12">
                <h4 style={{ fontFamily: "poppins" }}>KONTAK</h4>
                <hr />
                <div className="text-widget">
                  {/*<img src="/images/footer/alamat.png" alt="" />*/}
                  <span style={{ fontWeight: "1px" }}>
                    <h5
                      style={{
                        color: "white",
                        lineHeight: "20px",
                        fontSize: "1em",
                        fontFamily: "poppins"
                      }}
                    >
                      JL. Mustafa Daeng Bunga Kompleks Graha Surandar 3 Blok C2
                      No. 3 Gowa, Sulawesi-Selatan
                    </h5>
                  </span>
                  <h5
                    style={{
                      color: "white",
                      lineHeight: "20px",
                      fontSize: "1em",
                      fontFamily: "poppins"
                    }}
                  >
                    Phone: <span>0822 9381 6630</span>
                    <br />
                    Whatsapp: <span>0822 9381 6630</span>
                    <br />
                    E-Mail:
                    <span>
                      {" "}
                      <a href="#">letstudy.makassar@gmail.com</a>{" "}
                    </span>
                    <br />
                  </h5>
                </div>
              </div>

              <div className="col-md-2" />

              <div className="col-md-4  col-sm-12 offset-2">
                <h4 style={{ fontFamily: "poppins" }}>IKUTI KAMI DI</h4>
                <hr />
                <ul className="social-icons margin-top-20">
                  <li>
                    <div
                      onClick={e => {
                        window.location.href =
                          "https://www.instagram.com/letstudy.co/";
                      }}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundImage: "url(/images/icon/42xxxhdpi.png)",
                        backgroundSize: "100% 100%"
                      }}
                    />
                  </li>
                  <li>
                    <div
                      onClick={e => {
                        window.location.href =
                          " https://www.facebook.com/letstudy.co/";
                      }}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundImage: "url(/images/icon/43xxxhdpi.png)",
                        backgroundSize: "100% 100%"
                      }}
                    />
                  </li>
                  <li>
                    <div
                      onClick={e => {
                        window.location.href =
                          "https://www.youtube.com/channel/UC6QZ3hrigmSqCFrhVIpkMRw";
                      }}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundImage: "url(/images/icon/44xxxhdpi.png)",
                        backgroundSize: "100% 100%"
                      }}
                    />
                  </li>
                  <li>
                    <div
                      onClick={e => {
                        window.location.href = "http://line.me/ti/p/~@cie9905u";
                      }}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundImage: "url(/images/icon/45xxxhdpi.png)",
                        backgroundSize: "100% 100%"
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div style={{ fontFamily: "poppins" }} className="copyrights">
                  © 2018 LetStudy. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> //end div wrapper
    );
  }
}

export default landingPage;
