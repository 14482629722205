//module
import React, { Component } from "react";
import dateFormat from "dateformat";

class Riwayat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ props: nextProps.data });

    this.forceUpdate();
  }

  render() {
    const { props } = this.state;

    return (
      <div>
        <table className="basic-table">
          <thead>
            <tr>
              <th
                valign="bottom"
                rowSpan="2"
                style={{
                  background: "white",
                  color: "#333"
                }}
              >
                Nama
              </th>
              <th
                rowSpan="2"
                style={{
                  background: "white",
                  color: "#333"
                }}
              >
                Paket
              </th>
              <th
                style={{
                  background: "white",
                  color: "#333"
                }}
              >
                Ujian
              </th>
              <th
                style={{
                  background: "white",
                  color: "#333",
                  textAlign: "center"
                }}
              >
                Standar Kelulusan
              </th>
              <th
                style={{
                  background: "white",
                  color: "#333",
                  textAlign: "center"
                }}
              >
                Nilai
              </th>
              <th
                style={{
                  background: "white",
                  color: "#333"
                }}
              >
                Tanggal
              </th>
            </tr>
          </thead>
          <tbody>
            {props.ujian
              ? props.ujian.map((item, i) => (
                  <tr key={i}>
                    <td>{props.nama_user}</td>
                    <td>{props.nama_paket}</td>
                    <td>{item.nama_ujian}</td>
                    <td align="center">{item.standar_kelulusan}</td>
                    <td align="center">{item.jumlah_skor}</td>
                    <td>{dateFormat(item.tanggal, "d mmm yyyy, h:M TT ")}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Riwayat;
