//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import "jodit";
import JoditEditor from "jodit-react";
import axios from "axios";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

// Add the css styles...
import "jodit/build/jodit.min.css";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

class adminTentang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tentang: "",
      id_tentang: "",
      isLoaded: false
    };
  }

  componentDidMount() {
    //add script
    Edotensei.add(Scripts);
  }

  async componentWillMount() {
    try {
      //get data
      const result = await axios.get(process.env.REACT_APP_API + "/tentang/");

      //update state
      this.setState({
        tentang: result.data.data.kontent,
        id_tentang: result.data.data.id_tentang,
        isLoaded: true
      });
    } catch (error) {
      throw error;
    }
  }

  async updateTentang(e) {
    e.preventDefault();

    const { tentang, id_tentang } = this.state;

    try {
      //request
      await axios({
        url: process.env.REACT_APP_API + "/tentang",
        method: "put",
        data: {
          token: session.token,
          kontent: tentang,
          id_tentang: id_tentang
        }
      });

      //alet
      alert("Tentang berhasil disimpan");

      //re-get paket
      this.componentWillMount();
    } catch (error) {
      throw error;
    }
  }

  render() {
    //initial
    const { tentang } = this.state;
    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>

        <div hidden={!this.state.isLoaded}>
          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={this.updateTentang.bind(this)}>
                    <JoditEditor
                      value={tentang}
                      onChange={value => this.setState({ tentang: value })}
                    />
                    <br />
                    <button
                      style={{ float: "right", borderRadius: "5px" }}
                      className="button"
                    >
                      Simpan
                    </button>
                  </form>
                </div>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

adminTentang.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default adminTentang;
