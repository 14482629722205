//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import axios from "axios";
import dateFormat from "dateformat";
import ReactToPrint from "react-to-print";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";
import Riwayat from "./Include/Admin-nilai-ujian-component/Riwayat-peserta";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

class adminNilaiUjian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nilaiUjian: [],
      nilaiUjians: [],
      paket: [],
      cabang: [],
      isLoaded: false,

      //pagination
      currentPage: 1,
      todosPerPage: 10,

      //filter
      filterByPaket: 0,
      filterByNilai: 0,
      filterByCabang: "semua cabang",

      //riwayat on click
      riwayat: {}
    };
  }

  componentDidMount() {
    Edotensei.add(Scripts);
  }

  // handle search
  searchHandler(e) {
    //get paket
    var nilaiUjian = this.state.nilaiUjians;
    try {
      //update state
      this.setState({ currentPage: 1, filterByNilai: 0, filterByPaket: 0 });

      //filter
      nilaiUjian = nilaiUjian.filter(item => {
        //join
        var joinItem = item.nama_paket + " " + item.nama_user;

        //search
        return (
          joinItem.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });

      //update state
      this.setState({ nilaiUjian });
    } catch (error) {
      alert(error);
    }
  }

  // async component
  async componentWillMount() {
    try {
      //get data
      let [result, paket, cabang] = await Promise.all([
        axios.get(
          process.env.REACT_APP_API + "/admin-nilai-ujian/" + session.token
        ),
        axios.get(process.env.REACT_APP_API + "/paket"),
        axios.get(process.env.REACT_APP_API + "/cabang")
      ]);

      //update state
      this.setState({
        nilaiUjian: result.data.data,
        nilaiUjians: result.data.data,
        paket: paket.data.data,
        cabang: cabang.data.data,
        isLoaded: true
      });
    } catch (error) {
      throw error;
    }
  }

  filtering() {
    var {
      filterByNilai,
      filterByPaket,
      filterByCabang,
      nilaiUjian,
      nilaiUjians
    } = this.state;

    //filter by paket
    if (Number(filterByPaket) === 0) {
      nilaiUjian = nilaiUjians;
    } else {
      nilaiUjian = nilaiUjians.filter(item => {
        return item.paket === Number(filterByPaket);
      });
    }

    //filter by cabang
    if (filterByCabang === "semua cabang") {
      nilaiUjian = nilaiUjian;
    } else {
      nilaiUjian = nilaiUjian.filter(item => {
        return item.cabang.toLowerCase() === filterByCabang.toLocaleLowerCase();
      });
    }

    //filter by nilai
    if (Number(filterByNilai) === 0) {
      //ascending
      nilaiUjian.sort((a, b) => b.skor_akhir - a.skor_akhir);
    } else {
      nilaiUjian.sort((a, b) => a.skor_akhir - b.skor_akhir);
    }

    //update state
    this.setState({ nilaiUjian });
  }

  render() {
    const { nilaiUjian, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = nilaiUjian.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(nilaiUjian.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>
        <div hidden={!this.state.isLoaded}>
          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content" style={{ overflow: "auto" }}>
              <div className="row">
                <div className="col-md-12">
                  <div
                    style={{ overflow: "auto" }}
                    hidden={Object.keys(this.state.riwayat).length < 1}
                    className="dashboard-list-box margin-top-0"
                  >
                    <h4 className="clearfix">
                      Riwayat
                      <a
                        style={{ float: "right", borderRadius: "5px" }}
                        href="#!"
                        className="button"
                        onClick={e => {
                          e.preventDefault();
                          //update state
                          this.setState({ riwayat: {}, isLoaded: false });
                          //set time out
                          setTimeout(
                            () => this.setState({ isLoaded: true }),
                            1000
                          );
                        }}
                      >
                        Kembali
                      </a>
                    </h4>
                    <Riwayat data={this.state.riwayat} />
                  </div>

                  <div
                    hidden={Object.keys(this.state.riwayat).length > 1}
                    className="dashboard-list-box margin-top-0"
                  >
                    <h4 className="clearfix" style={{ position: "relative" }}>
                      <ReactToPrint
                        trigger={() => (
                          <a
                            style={{ float: "right", borderRadius: "5px" }}
                            href="#!"
                            className="button"
                          >
                            <i className="im im-icon-Printer" />
                            &nbsp; Print
                          </a>
                        )}
                        content={() => this.componentRef}
                      />

                      <div className="panel-dropdown wide float-right">
                        <a
                          className="button border"
                          style={{
                            borderRadius: "5px",
                            padding: "6px 15px",
                            float: "right"
                          }}
                          href="#!"
                        >
                          Filter
                        </a>
                        <div className="panel-dropdown-content checkboxes">
                          <div className="row">
                            <div className="col-md-12">
                              <h5>Filter berdasarkan paket</h5>
                              <select
                                value={this.state.filterByPaket}
                                onChange={e => {
                                  this.setState({
                                    filterByPaket: e.target.value
                                  });
                                }}
                              >
                                <option value="0">Semua Paket</option>
                                {this.state.paket.map((item, i) => (
                                  <option value={item.id_paket} key={i}>
                                    {item.nama_paket}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-12">
                              <h5>Filter berdasarkan cabang</h5>
                              <select
                                value={this.state.filterByCabang}
                                onChange={e => {
                                  this.setState({
                                    filterByCabang: e.target.value
                                  });
                                }}
                              >
                                <option value="semua cabang">
                                  Semua Cabang
                                </option>
                                {this.state.cabang.map((item, i) => (
                                  <option key={i} value={item.nama_cabang}>
                                    {item.nama_cabang}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-12">
                              <h5>Filter berdasarkan Nilai</h5>
                              <select
                                value={this.state.filterByNilai}
                                onChange={e =>
                                  this.setState({
                                    filterByNilai: e.target.value
                                  })
                                }
                              >
                                <option value="0">Tertinggi ke terendah</option>
                                <option value="1">Terendah ke tertinggi</option>
                              </select>
                            </div>

                            <div className="panel-buttons col-md-12">
                              <br />
                              <button className="panel-cancel">Cancel</button>
                              <button
                                className="panel-apply"
                                onClick={this.filtering.bind(this)}
                              >
                                Terapkan
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <i
                        style={{ top: "56%", position: "absolute" }}
                        className="fa  fa-search"
                      />
                      <input
                        style={{
                          marginBottom: "0",
                          borderColor: "transparent",
                          // borderBottom: "1px solid #dbdbdb",
                          boxShadow: "none"
                        }}
                        type="text"
                        className="input-text"
                        name="email"
                        placeholder="cari berdasarkan paket atau nama perserta"
                        autoComplete="off"
                        onChange={this.searchHandler.bind(this)}
                      />
                    </h4>
                    <div
                      className="table-print"
                      ref={el => (this.componentRef = el)}
                    >
                      <table id="table-nilai" className="basic-table">
                        <thead>
                          <tr>
                            <th
                              style={{
                                background: "white",
                                color: "#333"
                              }}
                            >
                              Nama
                            </th>
                            <th
                              style={{
                                background: "white",
                                color: "#333"
                              }}
                            >
                              Cabang
                            </th>
                            <th
                              style={{
                                background: "white",
                                color: "#333"
                              }}
                            >
                              Paket
                            </th>
                            <th
                              id="nilai"
                              style={{
                                background: "white",
                                color: "#333"
                              }}
                            >
                              Nilai
                            </th>
                            <th
                              style={{
                                background: "white",
                                color: "#333"
                              }}
                            >
                              Skor Akhir
                            </th>
                            <th
                              style={{
                                background: "white",
                                color: "#333"
                              }}
                            >
                              Tanggal
                            </th>
                            <th
                              id="riwayat"
                              style={{
                                background: "white",
                                color: "#333"
                              }}
                            />
                          </tr>
                        </thead>
                        <tbody>
                          {currentTodos.map((item, i) => (
                            <tr key={i}>
                              <td>{item.nama_user}</td>
                              <td>{item.cabang}</td>
                              <td>{item.nama_paket}</td>
                              <td width="30%" id="nilai-content">
                                {item.ujian.map((ujian, x) =>
                                  ujian.jumlah_skor >=
                                  ujian.standar_kelulusan ? (
                                    <a
                                      key={x}
                                      style={{
                                        borderRadius: "10px",
                                        background: "#00bfa5",
                                        color: "white",
                                        borderColor: "#00bfa5",
                                        cursor: "default",
                                        fontFamily: "Open Sans"
                                      }}
                                      href="#!"
                                      className="button border"
                                    >
                                      {ujian.nama_ujian +
                                        " : " +
                                        ujian.jumlah_skor}
                                    </a>
                                  ) : (
                                    <div>
                                      <a
                                        key={x}
                                        style={{
                                          borderRadius: "10px",
                                          background: "#2a2a2a",
                                          color: "white",
                                          borderColor: "#2a2a2a",
                                          cursor: "default",
                                          fontFamily: "Open Sans"
                                        }}
                                        href="#!"
                                        className="button border"
                                      >
                                        {ujian.nama_ujian +
                                          " : " +
                                          ujian.jumlah_skor}
                                      </a>
                                    </div>
                                  )
                                )}
                              </td>
                              <td>
                                <a
                                  className="button border"
                                  href="#!"
                                  style={{
                                    float: "left",
                                    borderRadius: "10px",
                                    backgroundColor: "#fafafa",
                                    borderColor: "#ddd",
                                    color: "#333",
                                    fontFamily: "Open Sans"
                                  }}
                                >
                                  {item.skor_akhir}
                                </a>
                              </td>
                              <td>
                                <strong style={{ fontFamily: "Open Sans" }}>
                                  {dateFormat(
                                    item.timestamp,
                                    "d mmm, h:M TT (yyyy)"
                                  )}
                                </strong>
                              </td>
                              <td id="riwayat-content" align="right">
                                <a
                                  className="button"
                                  style={{ borderRadius: "5px" }}
                                  href="#!"
                                  onClick={e => {
                                    e.preventDefault();

                                    //update state
                                    this.setState({
                                      riwayat: item,
                                      isLoaded: false
                                    });

                                    //set time out
                                    setTimeout(
                                      () => this.setState({ isLoaded: true }),
                                      1500
                                    );
                                  }}
                                >
                                  Riwayat
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div
                hidden={Object.keys(this.state.riwayat).length > 1}
                className="pagination-container margin-top-30 margin-bottom-0"
              >
                <nav className="pagination">
                  <ul>
                    {pageNumbers.map(number => (
                      <li key={number}>
                        <a
                          href="#!"
                          id={number}
                          className={
                            currentPage === number ? "current-page" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            //scroll up
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;

                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        >
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

adminNilaiUjian.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default adminNilaiUjian;
