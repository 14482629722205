//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import axios from "axios";
import dateFormat from "dateformat";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

class adminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jumlah_peserta: 0,
      total_ujian: 0,
      total_paket: 0,
      peserta_terdaftar: [],
      isLoaded: false,

      //pagination
      currentPage: 1,
      todosPerPage: 10
    };
  }

  async componentWillMount() {

    const { auth } = this.props
    
    try {
      //get data
      let result = await axios.get(
        process.env.REACT_APP_API + "/dashboard/" + session.token
      );

      //initial
      const { peserta, ujian, paket } = result.data.data;

      //update state
      this.setState({
        jumlah_peserta: peserta.length,
        total_ujian: ujian.length,
        total_paket: paket.length,
        peserta_terdaftar: peserta,
        isLoaded: true
      });

      //add scripts
      Edotensei.add(Scripts);

    } catch (error) {

      throw error;

    }
  }

  render() {
    const {
      jumlah_peserta,
      total_paket,
      total_ujian,
      peserta_terdaftar,
      todosPerPage,
      currentPage
    } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = peserta_terdaftar.slice(
      indexOfFirstTodo,
      indexOfLastTodo
    );

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(peserta_terdaftar.length / todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>

        <div hidden={!this.state.isLoaded}>
          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
            <div className="small-dialog-header">
              <h3>Detail Peserta</h3>
            </div>
            <div className="message-reply margin-top-0">
              <table width="100%">
                <thead>
                  <tr>
                    <td>Nama</td>
                    <td />
                  </tr>
                  <tr>
                    <td>Paket</td>
                    <td />
                  </tr>
                  <tr>
                    <td>User Level</td>
                    <td />
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content">
              <div id="titlebar">
                <div className="row">
                  <div className="col-md-12">
                    <h2>
                      Howdy,{" "}
                      {JSON.parse(localStorage.getItem("token")).displayName}!
                    </h2>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="dashboard-stat color-1">
                    <div className="dashboard-stat-content">
                      <h4>{jumlah_peserta}</h4> <span>Peserta Terdaftar</span>
                    </div>
                    <div className="dashboard-stat-icon">
                      <i className="im im-icon-Checked-User" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="dashboard-stat color-2">
                    <div className="dashboard-stat-content">
                      <h4>{total_ujian}</h4> <span>Total Ujian</span>
                    </div>
                    <div className="dashboard-stat-icon">
                      <i className="im im-icon-Books-2" />
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-3 col-md-6">
                <div className="dashboard-stat color-3">
                  <div className="dashboard-stat-content">
                    <h4>95</h4> <span>Total Jenis Ujian</span>
                  </div>
                  <div className="dashboard-stat-icon">
                    <i className="im im-icon-Book" />
                  </div>
                </div>
                </div> */}

                <div className="col-lg-4 col-md-6">
                  <div className="dashboard-stat color-4">
                    <div className="dashboard-stat-content">
                      <h4>{total_paket}</h4> <span>Total Paket</span>
                    </div>
                    <div className="dashboard-stat-icon">
                      <i className="im im-icon-Gift-Box" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row margin-top-10">
                <div className="col-md-12">
                  <div className="dashboard-list-box margin-top-0">
                    <h4>Peserta Terdaftar</h4>
                    <table className="basic-table">
                      <thead>
                        <tr>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Paket
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Nama Peserta
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Tanggal
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTodos.map((item, i) => (
                          <tr key={i}>
                            <td>{item.nama_paket}</td>
                            <td>{item.nama_user}</td>
                            <td>
                              {dateFormat(
                                item.timestamp,
                                "d mmm, h:M TT (yyyy)"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="pagination-container margin-top-30 margin-bottom-0">
                    <nav className="pagination">
                      <ul>
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <a
                              href="#!"
                              id={number}
                              className={
                                currentPage === number ? "current-page" : ""
                              }
                              onClick={e => {
                                e.preventDefault();
                                //scroll up
                                document.body.scrollTop = 0;
                                document.documentElement.scrollTop = 0;

                                //update state
                                this.setState({
                                  currentPage: Number(e.target.id)
                                });
                              }}
                            >
                              {number}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>

                  <div className="col-md-12">
                    <div className="copyrights">
                      © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

adminDashboard.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default adminDashboard;
