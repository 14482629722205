//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import axios from "axios";
// import dateFormat from "dateformat";
import Chart from "chart.js";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

class perkembaganNilai extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nilaiUjian: [],
      isLoaded: false,

      //pagination
      //pagination
      currentPage: 1,
      todosPerPage: 1
    };
  }

  componentDidMount() {
    //add script
    Edotensei.add(Scripts);
  }

  // async component
  async componentWillMount() {
    try {
      //get data
      let [result] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/nilai-ujian/" + session.token)
      ]);

      //update state
      this.setState({
        nilaiUjian: result.data.data,
        isLoaded: true
      });
    } catch (error) {
      throw error;
    }
  }

  render() {
    //initial
    const { nilaiUjian, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = nilaiUjian.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(nilaiUjian.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    var ctx = document.getElementById("canvas");

    if (ctx) {
      //get current value
      var result = currentTodos[0];

      //standar kelulusan
      var standarKelulusan = [];

      //nama ujian
      var label = [];

      //nilai per ujian
      var skor = [];

      if (result) {
        //perulangan
        for (const i in result.ujian) {
          //push standar kelulusan
          standarKelulusan.push(result.ujian[i].standar_kelulusan);

          //push nama ujian
          label.push(result.ujian[i].nama_ujian);

          //push nilai perujian
          skor.push(result.ujian[i].jumlah_skor);
        }
      }

      var data = {
        // The type of chart we want to create
        type: "line",

        // The data for our dataset
        data: {
          labels: label,
          datasets: [
            {
              label: "Standar Kelulusan",
              data: standarKelulusan,
              backgroundColor: "transparent",
              borderColor: "#00bfa5"
            },
            {
              label: "Nilai",
              data: skor,
              backgroundColor: "transparent",
              borderColor: "#f91942"
            }
          ]
        },

        //options
        options: {
          elements: {
            line: {
              tension: 0 // disables bezier curves
            }
          }
        }
      };

      //get context
      ctx = ctx.getContext("2d");

      //create chart
      new Chart(ctx, data);
    }

    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>
        <div hidden={!this.state.isLoaded}>
          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="dashboard-list-box margin-top-0"
                    style={{ width: "100%" }}
                  >
                    {currentTodos.map((item, i) => (
                      <h4
                        key={i}
                        className="clearfix"
                        style={{ position: "relative" }}
                      >
                        {item.nama_paket}
                      </h4>
                    ))}
                    <div style={{ padding: "20px 30px", background: "white" }}>
                      <canvas id="canvas" height="120" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pagination-container margin-top-30 margin-bottom-0">
                <nav className="pagination">
                  <ul>
                    {pageNumbers.map(number => (
                      <li key={number}>
                        <a
                          href="#!"
                          id={number}
                          className={
                            currentPage === number ? "current-page" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            //scroll up
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;

                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        >
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

perkembaganNilai.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default perkembaganNilai;
