import React, { Component } from "react";
import axios from "axios";
import md5 from "md5";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //login
      username: "",
      password: "",

      //register
      reg_username: "",
      reg_password: "",
      reg_repassword: "",
      reg_paket: "",

      //function
      isSubmit: false,
      paket: [],
      popup: "daftar"
    };
  }

  componentWillMount() {
    //get data
    let request = axios.get(process.env.REACT_APP_API + "/paket");

    //promis
    request
      .then(result => {
        //return value
        return result.data;
      })
      .then(result => {
        //update state
        this.setState({ paket: result.data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidMount() {
    //navbar selector
    let navbar = document.querySelector("#header");
    let sticky = navbar.offsetTop;

    window.onscroll = () => {
      if (window.pageYOffset > sticky) {
        //change id
        navbar.id = "header-sticky";
      } else {
        navbar.id = "header";
      }
    };
  }

  /**
   * session handler
   * @param {object} data
   */
  handleSessions(data) {
    //check users
    if (!data.ok) {
      //alert call
      alert("Username atau password yang anda masukkan salah!");

      //udpdate state
      this.setState({ password: "", isSubmit: false });

      //return
      return;
    }

    //make session
    localStorage.setItem(
      "token",
      JSON.stringify({
        token: data.token,
        displayName: data.displayName,
        userLevel: data.userLevel
      })
    );

    if (data.userLevel === 1) {
      //redirect
      window.location.href = "/dashboard";
    } else {
      //redirect
      window.location.href = "/dashboard/overview";
    }
  }

  /**
   * handle login
   * @param {event} e
   */
  loginHandler(e) {
    e.preventDefault();

    //update state
    this.setState({ isSubmit: true });

    //check users
    const request = axios.post(process.env.REACT_APP_API + "/login", {
      username: this.state.username,
      password: md5(this.state.password)
    });

    //promise request
    request
      .then(response => {
        //return response
        return response.data;
      })
      .then(this.handleSessions.bind(this))
      .catch(err => {
        //call alert
        alert("Internal server error");

        //update state
        this.setState({ isSubmit: false });
      });
  } //end login

  registerHandler(e) {
    //prevent
    e.preventDefault();

    //initial
    const { reg_username, reg_password, reg_repassword } = this.state;

    //check password
    if (reg_password !== reg_repassword) {
      //call alert
      alert("Password yang anda masukkan tidak cocok!");

      //update state
      this.setState({ reg_password: "", reg_repassword: "" });
    } else if (reg_password.length < 8) {
      //call alert
      alert("Password minimal 8 karakter");

      //update state
      this.setState({ reg_password: "", reg_repassword: "" });

      //return
      return;
    }

    let conf = window.confirm("Apakah data yang anda masukkan sudah benar?");

    if (!conf) return;

    //Restfull
    var request = axios.post(process.env.REACT_APP_API + "/users", {
      username: reg_username,
      password: md5(reg_password),
      paket: this.state.reg_paket,
      userLevel: 0
    });

    //promise
    request
      .then(res => {
        //alert
        alert("Registrasi berhasil, silahkan login untuk memulai ujian!");

        //reload page
        if (res.data.ok) {
          window.location.reload();
        }

        //update state
        this.setState({
          reg_username: "",
          reg_password: "",
          reg_repassword: ""
        });
      })
      .catch(err => {
        alert("Username sudah ada!");

        //update state
        this.setState({
          reg_username: "",
          reg_password: "",
          reg_repassword: ""
        });
      });
  } //end register

  logoutHandler(e) {
    e.preventDefault();

    //dialog
    let conf = window.confirm("Logout sekarang?");

    //verify
    if (!conf) return;

    //remove token
    localStorage.removeItem("token");

    //reload page
    window.location.href = "/";
  }

  render() {
    //get auth
    const { auth } = this.props;

    return (
      <div>
        <header id="header-container" className="fixed header-transparent">
          <div id="header" className="not-sticky">
            <div className="container">
              <div className="left-side">
                <div id="logo">
                  <a href="/">
                    <img src="/images/logo.png" alt="" />
                  </a>
                </div>

                <div className="mmenu-trigger">
                  <button
                    className="hamburger hamburger--collapse"
                    type="button"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                </div>
                <nav id="navigation" className="style-1">
                  <ul id="responsive">
                    <li>
                      <a href="/#home">
                        <b>Home</b>
                      </a>
                    </li>
                    <li>
                      <a href="/#tentang-kami">
                        <b>Tentang Kami</b>
                      </a>
                    </li>
                    <li>
                      <a href="/#testimoni">
                        <b>Testimoni</b>
                      </a>
                    </li>
                    <li>
                      <a href="/#footer">
                        <b>Kontak</b>
                      </a>
                    </li>
                    {auth ? (
                      <li className="mobile-hide">
                        {JSON.parse(localStorage.getItem("token")).userLevel ===
                        1 ? (
                          <li>
                            <a href="/dashboard">
                              <b> Dashboard </b>
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a href="/dashboard/overview">
                              <b> overview</b>
                            </a>
                          </li>
                        )}
                      </li>
                    ) : (
                      <li>
                        <li className="mobile-hide">
                          <a
                            style={{
                              padding: "0 30px",
                              borderColor: "#2060aa",
                              background: "transparent"
                            }}
                            href="#sign-in-dialog"
                            className="button border popup-with-zoom-anim"
                          >
                            <b>Masuk</b>
                          </a>
                        </li>
                        <li className="mobile-hide">
                          <a
                            href="#small-dialog"
                            className="button popup-with-zoom-anim"
                            style={{
                              padding: "0 30px",
                              background: "#2060aa",
                              color: "#fff"
                            }}
                            onClick={e => {
                              this.setState({ popup: "daftar" });
                            }}
                          >
                            <b>Daftar</b>
                          </a>
                        </li>
                      </li>
                    )}
                  </ul>
                </nav>
                <div className="clearfix" />
              </div>

              <div className="right-side">
                <div className="header-widget">
                  {auth ? (
                    <div className="user-menu">
                      <div className="user-name">
                        Hi,{" "}
                        {JSON.parse(localStorage.getItem("token")).displayName}
                      </div>
                      <ul>
                        {JSON.parse(localStorage.getItem("token")).userLevel ===
                        1 ? (
                          <li>
                            <a href="/dashboard">
                              <i className="sl sl-icon-settings" /> Dashboard
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a href="/dashboard/overview">
                              <i className="sl sl-icon-settings" /> overview
                            </a>
                          </li>
                        )}

                        <li>
                          <a
                            href="#!"
                            onClick={this.logoutHandler.bind(this)}
                            className="sign-in"
                          >
                            <i className="sl sl-icon-power" /> Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <center style={{ textAlign: "center" }}>
                      <a
                        style={{
                          marginLeft: "5px",
                          borderColor: "#2060aa",
                          background: "transparent"
                        }}
                        href="#sign-in-dialog"
                        className="button border popup-with-zoom-anim"
                      >
                        <b>Masuk</b>
                      </a>
                      <a
                        id="daftar"
                        href="#small-dialog"
                        className="button popup-with-zoom-anim"
                        style={{
                          background: "#2060aa",
                          color: "#fff"
                        }}
                        onClick={e => {
                          this.setState({ popup: "daftar" });
                        }}
                      >
                        <b>Daftar</b>
                      </a>
                    </center>
                  )}
                </div>
              </div>

              <div
                id="small-dialog"
                style={
                  this.state.popup === "daftar"
                    ? {
                        maxWidth: "500px",
                        background: "#2060aa",
                        paddingBottom: "10px"
                      }
                    : {
                        maxWidth: "500px",
                        background: "rgb(207, 31, 73)",
                        paddingBottom: "10px"
                      }
                }
                className="zoom-anim-dialog mfp-hide"
              >
                <div
                  style={
                    this.state.popup === "daftar"
                      ? { background: "#2060aa", marginBottom: "0" }
                      : { background: "rgb(207, 31, 73)", marginBottom: "0" }
                  }
                  className="small-dialog-header"
                >
                  <center>
                    <button
                      className="button"
                      style={
                        this.state.popup === "daftar"
                          ? {
                              background: "white",
                              color: "#2060aa",
                              cursor: "default"
                            }
                          : {
                              background: "white",
                              color: "rgb(207, 31, 73)",
                              cursor: "default"
                            }
                      }
                    >
                      <b>
                        {this.state.popup === "daftar"
                          ? "Daftar"
                          : "Lupa Kata Sandi"}
                      </b>
                    </button>
                  </center>
                </div>
                <br />
                <br />
                {this.state.popup === "daftar" ? (
                  <div>
                    <p align="center" style={{ color: "white" }}>
                      Segera <br /> melakukan pendaftaran <br /> di kantor
                      Letstudy
                    </p>
                    <h4 align="center" style={{ color: "white" }}>
                      #WeCareAboutYourStudy
                    </h4>
                  </div>
                ) : (
                  <div>
                    <p align="center" style={{ color: "white" }}>
                      Segera <br /> menghubungi <br /> kantor pusat Letstudy
                    </p>{" "}
                  </div>
                )}

                <br />
                <br />
                <br />
                <h5
                  align="center"
                  style={{ color: "white", lineHeight: "20px" }}
                >
                  JL. Mustafa Daeng Bunga <br /> Kompleks Graha Surandar 3{" "}
                  <br /> Blok C2 No. 3 Gowa, Sulawesi-Selatan <br /> 0822 9381
                  6630
                </h5>
              </div>

              <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">
                <div
                  style={{ background: "transparent", marginBottom: "0" }}
                  className="small-dialog-header"
                >
                  <h3>Masuk</h3>
                </div>

                <div className="sign-in-form style-1">
                  <div className="tabs-container alt">
                    <div className="tab-content" id="tab1">
                      <form
                        onSubmit={this.loginHandler.bind(this)}
                        className="login"
                      >
                        <p className="form-row form-row-wide">
                          <label htmlFor="username">
                            Username:
                            <i className="im im-icon-Male" />
                            <input
                              type="text"
                              className="input-text"
                              name="username"
                              id="username"
                              onChange={e =>
                                this.setState({ username: e.target.value })
                              }
                              value={this.state.username}
                              disabled={this.state.isSubmit}
                              required
                            />
                          </label>
                        </p>

                        <p className="form-row form-row-wide">
                          <label htmlFor="password">
                            Password:
                            <i className="im im-icon-Lock-2" />
                            <input
                              className="input-text"
                              type="password"
                              name="password"
                              id="password"
                              onChange={e =>
                                this.setState({ password: e.target.value })
                              }
                              value={this.state.password}
                              disabled={this.state.isSubmit}
                              required
                            />
                          </label>
                        </p>

                        <div className="form-row">
                          <input
                            type="submit"
                            className="button border margin-top-5"
                            name="login"
                            disabled={this.state.isSubmit}
                            value={
                              this.state.isSubmit ? "Loading ..." : "Login"
                            }
                          />
                        </div>

                        <div style={{ float: "right" }}>
                          <span
                            style={{ marginRight: "10px" }}
                            className="lost_password"
                          >
                            <a
                              href="#!"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({ popup: "lupa kata sandi" });
                                document.querySelector("#daftar").click();
                              }}
                            >
                              Lupa Kata Sandi
                            </a>
                          </span>
                          <span className="lost_password">
                            <a
                              href="#!"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({ popup: "daftar" });
                                document.querySelector("#daftar").click();
                              }}
                            >
                              Daftar
                            </a>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="clearfix" />
      </div>
    );
  }
}

export default Navbar;
