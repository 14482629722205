//module
import React, { Component } from "react";
import propTypes from "prop-types";
import axios from "axios";
import firebase from "firebase/app";
import Select from "react-select";
import "jodit";
import JoditEditor from "jodit-react";

//component
import Navbar from "../dashboard-navbar";
import Sidebar from "../dashboard-sidebar";

// Add the css styles...1
import "react-widgets/dist/css/react-widgets.css";
import "jodit/build/jodit.min.css";
import "firebase/storage"

//get session
const session = JSON.parse(localStorage.getItem("token"));

//tempat menampuang jawaban
var arrJawaban = [{}];

class EditSoal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmit: false,
      //data awal
      ujian: [""],
      alfabet: [],
      jumlahJawaban: 5,
      listSoal: [],
      loader: [],

      //tambah soal
      pilih_ujian: [],
      soal: " ",
      jawaban: [],
      jawabanBenar: "",
      pembahasan: "",
      file: []
    };
  }

  /**
   * @property Jodit jodit instance of native Jodit
   */
  jodit;
  setRef = jodit => (this.jodit = jodit);

  config = {
    uploader: {
      insertImageAsBase64URI: false,
      url: process.env.REACT_APP_API + "/file",
      imagesExtensions: ["jpg", "png", "jpeg", "gif"],
      method: "POST",
      isSuccess: resp => false,
      getMessage: resp => "Sedang mengupload gambar",
      buildData: data => {

        return new Promise((resolve, reject) => {

          const urltoFile = (url, filename, mimeType) => {

            return (fetch(url)

              .then(function (res) { return res.arrayBuffer(); })

              .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            )
          }

          const reader = new FileReader()

          reader.readAsDataURL(data.getAll('files[0]')[0])

          reader.onload = async () => {

            const file = await urltoFile(reader.result, "test.jpg", "image/jpeg")

            const storageRef = firebase.storage().ref()

            const campaignImageRef = storageRef.child("soal/" + file.lastModified + ".jpg")

            alert("sedang mengungggah gambar")

            const snap = campaignImageRef.put(file)

            snap.on("state_changed", snapshot => { }, err => { console.log(err) }, async () => {

              const url = await snap.snapshot.ref.getDownloadURL()

              const soal = this.state.soal + ` <img src=${url} alt=${url} />`

              this.setState({ soal })

            })

            return resolve({

              image: file

            })
          }
        })
      }
    }
  }

  config2 = {
    uploader: {
      insertImageAsBase64URI: false,
      url: process.env.REACT_APP_API + "/file",
      imagesExtensions: ["jpg", "png", "jpeg", "gif"],
      method: "POST",
      isSuccess: resp => false,
      getMessage: resp => "Sedang mengupload gambar",
      buildData: data => {

        return new Promise((resolve, reject) => {

          const urltoFile = (url, filename, mimeType) => {

            return (fetch(url)

              .then(function (res) { return res.arrayBuffer(); })

              .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            )
          }

          const reader = new FileReader()

          reader.readAsDataURL(data.getAll('files[0]')[0])

          reader.onload = async () => {

            const file = await urltoFile(reader.result, "test.jpg", "image/jpeg")

            const storageRef = firebase.storage().ref()

            const campaignImageRef = storageRef.child("soal/" + file.lastModified + ".jpg")

            alert("sedang mengungggah gambar")

            const snap = campaignImageRef.put(file)

            snap.on("state_changed", snapshot => { }, err => { console.log(err) }, async () => {

              const url = await snap.snapshot.ref.getDownloadURL()

              const pembahasan = this.state.pembahasan + ` <img src=${url} alt=${url} />`

              this.setState({ pembahasan })

            })

            return resolve({

              image: file

            })
          }
        })
      }
    }
  }


  componentDidMount() {
    //buat form input jawaban dinamis
    var alfabet = [];
    var i = "A".charCodeAt(0);
    var j = "Z".charCodeAt(0);

    for (; i <= j; ++i) {
      alfabet.push(String.fromCharCode(i));
    }

    //update state
    this.setState({
      alfabet
    });
  }

  // async component
  async componentWillMount() {
    //get id soal
    const { id_soal } = this.props.match.params;

    try {
      //get data
      let [ujian, getData] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/ujian"),
        axios.get(process.env.REACT_APP_API + "/soal/by/" + id_soal)
      ]);

      getData.data.data = getData.data.data[0];

      //intial
      const soal = getData.data.data;
      const ujian_soal = getData.data.data.list_ujian;
      const soal_pilihan = getData.data.data.soal_pilihan;

      //verify id
      if (getData.data.data.length <= 0)
        return (window.location.href = "/dashboard/soal");

      //intial
      var list_ujian = [];
      var value_ujian = [];
      var jawaban_point = [];

      //parse array to object label and value ujian
      for (const i in ujian.data.data) {
        list_ujian.push({
          value: ujian.data.data[i].id_ujian,
          label: ujian.data.data[i].nama_ujian,
          id: ujian.data.data[i]
        });
      }

      //parse array to object label and value
      for (const i in ujian_soal) {
        value_ujian.push({
          value: ujian_soal[i].id_ujian,
          label: ujian_soal[i].nama_ujian
        });
      }

      //parse array to object label and value
      for (const i in soal_pilihan) {
        jawaban_point.push({
          label: soal_pilihan[i].label,
          jawaban: soal_pilihan[i].jawaban,
          point: soal_pilihan[i].point
        });
      }

      //update array dari jawaban
      arrJawaban = jawaban_point;

      //update state
      this.setState({
        ujian: list_ujian,
        pilih_ujian: value_ujian,
        soal: soal.pertanyaan,
        jawabanBenar: soal.jawaban,
        jumlahJawaban: soal_pilihan.length,
        jawaban: jawaban_point,
        pembahasan: soal.pembahasan,
        loader: value_ujian
      });
    } catch (error) {
      throw error;
    }
  }

  async inputFileHandler(e) {
    if (e.target.files.length < 1) return;

    //get the file
    const file = e.target.files;

    //update state
    this.setState({ file: file[0] });

    //headers
    let headers = new Headers();

    //create any headers we want
    headers.append("Accept", "application/json");

    //bundle the files and data we want to send to the server
    let formData = new FormData();

    //create any form data
    formData.append("file", e.target.files[0]);

    //try
    try {
      const result = await axios.post(
        process.env.REACT_APP_API + "/file",
        formData,
        headers
      );

      var html = "";

      //check type
      if (result.data.type === "video") {
        html =
          "<center> <video width='420' height='340' controls> <source src=" +
          process.env.REACT_APP_API +
          "/" +
          result.data.path +
          " /> </video> </center>";
      } else {
        html =
          " <img src=" +
          process.env.REACT_APP_API +
          "/" +
          result.data.path +
          " />";
      }

      //update state
      this.setState({
        pembahasan: this.state.pembahasan + html
      });
    } catch (error) {
      alert(error);
    }
  }

  // Add handler
  async updateSoalHandler(e) {
    e.preventDefault();

    //initial
    const { id_soal } = this.props.match.params;
    const soal_pilihan = this.state.jawaban;

    //validate
    if (soal_pilihan.length <= 0) return alert("Jawaban wajib diisi!");

    const length = soal_pilihan.length - 1;

    for (let i in soal_pilihan) {


      if (!soal_pilihan[i].point) return alert('point tidak boleh kosong')

      if (soal_pilihan[i].point > 0) break

      if (i == length) return alert('salah satu point jawaban harus lebih dari 0')

    }

    //data
    var soal = {
      id_soal,
      pertanyaan: this.state.soal,
      type: "pilihan ganda",
      jawaban: this.state.jawabanBenar,
      pembahasan: this.state.pembahasan,
      soal_pilihan: JSON.stringify(soal_pilihan),
      jenis_soal: ""
    };

    var ujian_soal = this.state.pilih_ujian;

    //validate
    if (ujian_soal.length <= 0) return alert("Ujian wajib diisi!");

    if (soal.pertanyaan.length <= 0) return alert("Soal wajib diisi!");

    if (soal.jawaban === "") return alert("Jawaban benar wajib diisi!");

    //confirm
    if (!window.confirm("Simpan soal ini?")) return;

    this.setState({ isSubmit: true })

    try {
      //request
      const result = await axios({
        url: process.env.REACT_APP_API + "/soal",
        method: "put",
        data: {
          token: session.token,
          id_soal,
          soal,
          ujian_soal
        }
      });

      //alet
      alert(result.data.message);

      this.setState({ isSubmit: false })

      //redirect
      window.location.href = "/dashboard/soal";
    } catch (error) {

      this.setState({ isSubmit: false })

      throw error
    }
  }

  render() {
    //intial
    const { jawaban } = this.state;

    //loader
    if (this.state.loader.length <= 0) {
      return (
        <div>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>
      );
    }

    return (
      <div>
        {/** navbar */}
        <Navbar />

        <div id="dashboard">
          {/** sidebar */}
          <Sidebar level={this.props.userLevel} />

          {/** content / overview */}
          <div style={{ paddingTop: "0" }} className="dashboard-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{ maxWidth: "100%" }}
                  id="small-dialog"
                  className=""
                >
                  <div className="message-reply margin-top-0">
                    <form onSubmit={this.updateSoalHandler.bind(this)}>
                      <div className="row with-forms">
                        <div className="col-md-12">
                          <br />
                          <h5>Ujian</h5>
                          <Select
                            placeholder=""
                            isMulti={true}
                            options={this.state.ujian}
                            defaultValue={this.state.pilih_ujian}
                            onChange={selected => {
                              this.setState({ pilih_ujian: selected });
                            }}
                          />
                        </div>

                        <div className="col-md-12">
                          <br />
                          <h5>Soal</h5>
                          <JoditEditor
                            config={this.config}
                            editorRef={this.setRef}
                            value={this.state.soal}
                            placeholder=""
                            onChange={value => (this.state.soal = value)}
                          />
                        </div>

                        <div style={{ padding: 0 }} className="col-md-12">
                          <br />
                          <h5 style={{ paddingLeft: "10px" }}>Jawaban</h5>
                          <div className="col-md-12">
                            <table width="100%">
                              <tbody>
                                {this.state.alfabet
                                  .slice(0, this.state.jumlahJawaban)
                                  .map((item, i) => (
                                    <tr key={i}>
                                      <td width="5%">
                                        <i
                                          style={{
                                            fontStyle: "normal",
                                            fontSize: "16px"
                                          }}
                                        >
                                          {item}.
                                        </i>
                                      </td>
                                      <td width="55%">
                                        {/*
                                        <div className="input-with-icon medium-icons">
                                          <input
                                            type="text"
                                            autoComplete="false"
                                            name={"jawaban" + item}
                                            value={
                                              jawaban[i]
                                                ? jawaban[i].jawaban
                                                : ""
                                            }
                                            onChange={e => {
                                              arrJawaban[i] = {
                                                label: item,
                                                jawaban: e.target.value
                                              };
                                              this.setState({
                                                jawaban: arrJawaban
                                              });
                                            }}
                                            required
                                          />
                                          <i
                                            style={{
                                              fontStyle: "normal",
                                              fontSize: "16px"
                                            }}
                                          >
                                            {item}
                                          </i>
                                          </div> 
                                        */}
                                        <div>
                                          <JoditEditor
                                            config={{
                                              toolbarButtonSize: "small",
                                              uploader: {
                                                insertImageAsBase64URI: false,
                                                url: process.env.REACT_APP_API + "/file",
                                                imagesExtensions: ["jpg", "png", "jpeg", "gif"],
                                                method: "POST",
                                                format: "json",
                                                queryBuild: data => JSON.stringify(data),
                                                contentType: () => 'application/json',
                                                isSuccess: resp => false,
                                                getMessage: resp => "Sedang mengupload gambar",
                                                buildData: data => {

                                                  return new Promise((resolve, reject) => {

                                                    const urltoFile = (url, filename, mimeType) => {

                                                      return (fetch(url)

                                                        .then(function (res) { return res.arrayBuffer(); })

                                                        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
                                                      )
                                                    }

                                                    const reader = new FileReader()

                                                    reader.readAsDataURL(data.getAll('files[0]')[0])

                                                    reader.onload = async () => {

                                                      const file = await urltoFile(reader.result, "test.jpg", "image/jpeg")

                                                      const storageRef = firebase.storage().ref()

                                                      const campaignImageRef = storageRef.child("soal/" + file.lastModified + ".jpg")

                                                      const snap = campaignImageRef.put(file)

                                                      snap.on("state_changed", snapshot => { }, err => { console.log(err) }, async () => {

                                                        const url = await snap.snapshot.ref.getDownloadURL()

                                                        // const pembahasan = this.state.pembahasan + `<img src=${url} alt=${url} />`

                                                        arrJawaban[i] = { label: item, jawaban: jawaban[i] ? jawaban[i].jawaban + `<img src=${url} alt=${url} />` : "" + `<img src=${url} alt=${url} />`, point: jawaban[i] ? jawaban[i].point : false }

                                                        this.setState({ jawaban: arrJawaban })

                                                      })

                                                      return resolve({

                                                        image: file

                                                      })
                                                    }
                                                  })
                                                }
                                              }
                                            }}
                                            value={jawaban[i] ? jawaban[i].jawaban : ""}
                                            editorRef={this.setRef}
                                            onChange={value => {
                                              arrJawaban[i] = {
                                                label: item,
                                                jawaban: value,
                                                point: jawaban[i] ? jawaban[i].point : false
                                              };

                                              this.state.jawaban = arrJawaban;
                                            }}
                                          />
                                          <br />
                                        </div>
                                      </td>
                                      <td width="20%" align="right" >
                                        <input
                                          style={{ width: "80%" }}
                                          placeholder="point"
                                          type="number"
                                          value={
                                            jawaban[i] && jawaban[i].point
                                              ? jawaban[i].point
                                              : ""
                                          }
                                          onChange={e => {
                                            arrJawaban[i]
                                              ? (arrJawaban[i].point =
                                                e.target.value)
                                              : alert(
                                                "Input jawaban terlebih dahulu"
                                              );
                                            this.setState({
                                              jawaban: arrJawaban
                                            });
                                          }}
                                          required
                                        />
                                      </td>
                                      <td align="right" valign="middle">
                                        {i + 1 === this.state.jumlahJawaban ? (
                                          <p>
                                            {this.state.jumlahJawaban ===
                                              1 ? null : (
                                                <i
                                                  style={{
                                                    fontSize: "30px",
                                                    cursor: "pointer",
                                                    color: "#f91942",
                                                    marginTop: "12px",
                                                    marginRight: "10px"
                                                  }}
                                                  onClick={() => {
                                                    arrJawaban.splice(
                                                      this.state.jumlahJawaban -
                                                      1,
                                                      1
                                                    );

                                                    this.setState({
                                                      jumlahJawaban:
                                                        this.state.jumlahJawaban -
                                                        1,
                                                      jawaban: arrJawaban,
                                                      jawabanBenar: ""
                                                    });
                                                  }}
                                                  className="fa fa-minus-square-o"
                                                />
                                              )}

                                            <i
                                              style={{
                                                fontSize: "30px",
                                                cursor: "pointer",
                                                color: "#f91942",
                                                marginTop: "12px"
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  jumlahJawaban:
                                                    this.state.jumlahJawaban + 1
                                                })
                                              }
                                              className="fa fa-plus-square-o"
                                            />
                                          </p>
                                        ) : null}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h5>Jawaban yang benar</h5>
                          <select
                            value={this.state.jawabanBenar}
                            onChange={e =>
                              this.setState({ jawabanBenar: e.target.value })
                            }
                          >
                            <option value="" />
                            {this.state.alfabet
                              .slice(0, this.state.jumlahJawaban)
                              .map((item, i) => (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              ))}
                          </select>
                        </div>

                        {/** <div className="col-md-12">
                          <h5>File terkait (gambar atau video)</h5>
                          <label
                            style={{
                              height: "51px",
                              border: "1px solid #dbdbdb",
                              borderRadius: "3px",
                              padding: "10px 20px",
                              cursor: "pointer"
                            }}
                            htmlFor="input-file"
                          >
                            {this.state.file.name
                              ? this.state.file.name
                              : "Pilih File Gambar atau Video"}
                            <input
                              hidden={true}
                              accept="image/*, video/*"
                              onChange={this.inputFileHandler.bind(this)}
                              id="input-file"
                              type="file"
                            />
                          </label>
                        </div>*/}

                        <div className="col-md-12">
                          <h5>Pembahasan Soal</h5>
                          <JoditEditor
                            config={this.config2}
                            editorRef={this.setRef}
                            value={this.state.pembahasan}
                            onChange={value => (this.state.pembahasan = value)}
                          />
                        </div>

                        <div className="col-md-12">
                          <br />
                          <button
                            type="button"
                            onClick={() =>
                              (window.location.href = "/dashboard/soal")
                            }
                            className="button"
                          >
                            {" "}
                            Kembali
                          </button>
                          <button className="button"> {this.state.isSubmit ? "Loading..." : "Simpan"}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditSoal.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default EditSoal;
