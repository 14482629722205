//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import axios from "axios";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

class cabang extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cabang: [],
      cabangs: [""],
      currentPage: 1,
      todosPerPage: 5,
      isEdit: false,
      id_cabang: false,
      isLoaded: false,

      //add cabang
      nama_cabang: "",
      alamat_cabang: "",
      kota: ""
    };

    this.addCabangHandler = this.addCabangHandler.bind(this);
  }

  componentDidMount() {
    Edotensei.add(Scripts);
  }

  // async component
  async componentWillMount() {
    try {
      //get data
      let result = await axios.get(process.env.REACT_APP_API + "/cabang");

      //update state
      this.setState({
        cabang: result.data.data,
        cabangs: result.data.data,
        isLoaded: true
      });
    } catch (error) {
      throw error;
    }
  }

  // handle search
  searchHandler(e) {
    //get cabang
    var cabang = this.state.cabangs;

    try {
      //update state
      this.setState({ currentPage: 1 });

      //filter
      cabang = cabang.filter(item => {
        var joinItem =
          item.nama_cabang + " " + item.alamat_cabang + " " + item.kota;

        return (
          joinItem.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });

      //update state
      this.setState({ cabang });
    } catch (error) {
      alert(error);
    }
  }

  //handle tambah cabang
  async addCabangHandler(e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Apakah data yang anda masukkan sudah benar?")) return;

    try {
      //request
      await axios.post(process.env.REACT_APP_API + "/cabang", {
        token: session.token,
        nama_cabang: this.state.nama_cabang,
        alamat_cabang: this.state.alamat_cabang,
        kota: this.state.kota
      });

      //close modal
      document.querySelector(".mfp-close").click();

      //alert
      alert("Cabang berhasil ditambahkan");

      //re-get cabang
      this.componentWillMount();
    } catch (error) {
      throw error;
    }
  }

  async editCabangHandler(e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Simpan cabang ini?")) return;

    try {
      //request
      await axios({
        url: process.env.REACT_APP_API + "/cabang",
        method: "put",
        data: {
          token: session.token,
          id_cabang: this.state.id_cabang,
          nama_cabang: this.state.nama_cabang,
          alamat_cabang: this.state.alamat_cabang,
          kota: this.state.kota
        }
      });

      //close modal
      document.querySelector(".mfp-close").click();

      //alert
      alert("Cabang berhasil diperbartui");

      //re-get cabang
      this.componentWillMount();
    } catch (error) {
      throw error;
    }
  }

  //handle delete
  async deleteHandler(id_cabang, e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Hapus cabang ini?")) return;

    await axios({
      url: process.env.REACT_APP_API + "/cabang",
      method: "delete",
      data: {
        token: session.token,
        id_cabang
      }
    });

    //re-get cabang
    this.componentWillMount();
  }

  render() {
    //initial
    const { cabang, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = cabang.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(cabang.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>

        <div hidden={!this.state.isLoaded}>
          {/** modals */}
          <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
            <div className="small-dialog-header">
              <h3>{this.state.isEdit ? "Edit cabang" : "Tambah cabang"}</h3>
            </div>
            <div className="message-reply margin-top-0">
              <form
                onSubmit={
                  this.state.isEdit
                    ? this.editCabangHandler.bind(this)
                    : this.addCabangHandler
                }
              >
                <div className="row with-forms">
                  <div className="col-md-12">
                    <h5>Nama cabang</h5>
                    <input
                      className="search-field"
                      type="text"
                      name="nama_cabang"
                      value={this.state.nama_cabang}
                      onChange={e => {
                        this.setState({ nama_cabang: e.target.value });
                      }}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <h5>Alamat cabang</h5>
                    <input
                      className="search-field"
                      type="text"
                      name="alamat_cabang"
                      value={this.state.alamat_cabang}
                      onChange={e => {
                        this.setState({ alamat_cabang: e.target.value });
                      }}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <h5>Kota</h5>
                    <input
                      className="search-field"
                      type="text"
                      name="kota"
                      value={this.state.kota}
                      onChange={e => {
                        this.setState({ kota: e.target.value });
                      }}
                      required
                    />
                  </div>
                </div>

                <button className="button">
                  {" "}
                  {this.state.isEdit ? "Simpan" : "Tambahkan"}
                </button>
              </form>
            </div>
          </div>

          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard-list-box margin-top-0">
                    <h4 className="clearfix" style={{ position: "relative" }}>
                      <a
                        style={{ float: "right", borderRadius: "5px" }}
                        href="#small-dialog"
                        className="button popup-with-zoom-anim"
                        id="modal"
                        onClick={() => {
                          this.setState({
                            isEdit: false,
                            id_cabang: "",
                            nama_cabang: "",
                            alamat_cabang: "",
                            kota: ""
                          });
                        }}
                      >
                        <i className="sl sl-icon-plus" />
                        &nbsp; Tambah Cabang
                      </a>

                      <i
                        style={{ top: "56%", position: "absolute" }}
                        className="fa  fa-search"
                      />
                      <input
                        style={{
                          marginBottom: "0",
                          borderColor: "transparent",
                          // borderBottom: "1px solid #dbdbdb",
                          boxShadow: "none"
                        }}
                        type="text"
                        className="input-text"
                        name="email"
                        placeholder="cari berdasarkan nama cabang, alamat cabang atau kota"
                        autoComplete="off"
                        onChange={this.searchHandler.bind(this)}
                      />
                    </h4>

                    <table className="basic-table">
                      <thead style={{ background: "white" }}>
                        <tr>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Nama Cabang
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Alamat Cabang
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Kota
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {currentTodos.length > 0 ? (
                          currentTodos.map((item, i) => (
                            <tr key={i}>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="20%"
                              >
                                {item.nama_cabang}
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="25%"
                              >
                                {item.alamat_cabang}
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="20%"
                              >
                                {item.kota}
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="35%"
                                align="right"
                              >
                                <a
                                  href="#!"
                                  className="button border"
                                  onClick={e => {
                                    e.preventDefault();

                                    //open modal
                                    document.querySelector("#modal").click();

                                    //update state
                                    this.setState({
                                      id_cabang: item.id_cabang,
                                      nama_cabang: item.nama_cabang,
                                      alamat_cabang: item.alamat_cabang,
                                      kota: item.kota,
                                      isEdit: true
                                    });
                                  }}
                                >
                                  Edit Cabang
                                </a>

                                <a
                                  href="#!"
                                  onClick={this.deleteHandler.bind(
                                    this,
                                    item.id_cabang
                                  )}
                                  className="button border"
                                >
                                  Hapus Cabang
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" align="center">
                              <h3>Cabang Tidak Ditemukan</h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="pagination-container margin-top-30 margin-bottom-0">
                <nav className="pagination">
                  <ul>
                    {pageNumbers.map(number => (
                      <li key={number}>
                        <a
                          href="#!"
                          id={number}
                          className={
                            currentPage === number ? "current-page" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            //scroll up
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;

                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        >
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

cabang.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default cabang;
