//module
import React, { Component } from "react";
import axios from "axios";
import Cryptr from "cryptr";
import ReactHtmlParser from "react-html-parser";
import Edotensei from "edotensei";

//get token
const session = JSON.parse(localStorage.getItem("token"));

//encrypt
const cryptr = new Cryptr(process.env.REACT_APP_SECRET_KEY);

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

class scoring extends Component {
  constructor(props) {
    super();

    this.state = {
      score: [],
      pembahasan: [],
      totalScore: 0,
      isLoaded: false,
      hide_pembahasan: true,
      detail: {},
      _score: "",

      //pagination
      currentPage: 1,
      todosPerPage: 5
    };
  }

  async componentWillMount() {
    try {
      var [hasil] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/hasil-ujian/" + session.token)
      ]);

      var totalScore = 0;

      for (const i in hasil.data.data) {
        // totalScore += hasil.data.data[i].jumlah_skor;
        totalScore += hasil.data.data[i].jumlah_skor;
      }

      this.setState({
        score: hasil.data.data,
        _score: hasil.data.data[0],
        totalScore,
        pembahasan: JSON.parse(
          cryptr.decrypt(localStorage.getItem("pembahasan"))
        ).data.data,
        isLoaded: true
      });

      Edotensei.add(Scripts);
    } catch (error) {
      window.location.href = "/pilih-ujian";
    }
  }

  render() {
    //initial
    const { score, pembahasan, detail, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = pembahasan.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(pembahasan.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    console.log(this.state._score);

    return (
      <div style={{ background: "#f5f5f5" }}>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>
        <div hidden={!this.state.isLoaded}>
          {/** modal */}
          <div
            id="small-dialog"
            style={{ maxWidth: "80%" }}
            className="zoom-anim-dialog mfp-hide"
          >
            <div className="small-dialog-header">
              <h3>Pembahasan Soal Nomor {detail.no}</h3>
            </div>
            <div className="message-reply margin-top-0">
              <p>{ReactHtmlParser(detail.pembahasan)}</p>
            </div>
          </div>

          {/** content */}
          <div className="container margin-top-20">
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{ background: "white" }}
                  className="dashboard-list-box margin-top-0"
                >
                  <h4 className="clearfix" style={{ position: "relative" }}>
                    <div hidden={this.state.hide_pembahasan}>
                      <button
                        className="button"
                        style={{ borderRadius: "5px" }}
                        onClick={() => {
                          this.setState({
                            hide_pembahasan: !this.state.hide_pembahasan
                          });
                        }}
                      >
                        Kembali
                      </button>
                    </div>
                    <div hidden={!this.state.hide_pembahasan}>
                      <span style={{ fontSize: "2em" }}>Skor Akhir</span>

                      <center>
                        <table
                        
                          className="basic-table margin-top-80"
                        >
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  borderRadius: "0",
                                  backgroundColor: "#118def",
                                  borderRadius: "0"
                                }}
                                colSpan="4"
                              >
                                Point
                              </th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  fontSize: "2.5em",
                                  backgroundColor: "#118def",
                                  borderRadius: "0"
                                }}
                                colSpan="4"
                              >
                                {this.state._score.jumlah_skor}
                              </th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#118def",
                                  borderRadius: "0"
                                }}
                              >
                                Nama
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#118def",
                                  borderRadius: "0"
                                }}
                              >
                                Subject
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#118def",
                                  borderRadius: "0"
                                }}
                              >
                                Benar
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  backgroundColor: "#118def",
                                  borderRadius: "0"
                                }}
                              >
                                Waktu
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.5em",
                                  textTransform: "uppercase"
                                }}
                              >
                                {
                                  JSON.parse(localStorage.getItem("token"))
                                    .displayName
                                }
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.5em"
                                }}
                              >
                                {this.state._score.nama_ujian}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.5em"
                                }}
                              >
                                {this.state._score.benar} Soal
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "1.5em"
                                }}
                              >
                                {this.state._score.lama_pengerjaan}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table width="100%">
                          <thead>
                            <tr>
                              <th />
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {
                              //score.map((item, i) => (
                              //   <tr key={i}>
                              //     <td
                              //       align="center"
                              //       style={{ fontSize: "1.5em" }}
                              //     >
                              //       <br />
                              //       {item.nama_ujian}
                              //     </td>
                              //     <td
                              //       align="center"
                              //       style={{ fontSize: "1.5em" }}
                              //     >
                              //       <br />
                              //       <a
                              //         href="#!"
                              //         style={{
                              //           borderRadius: "10px",
                              //           background: "#00bfa5",
                              //           color: "white",
                              //           borderColor: "#00bfa5",
                              //           cursor: "default"
                              //         }}
                              //         className="button border"
                              //       >
                              //         {item.jumlah_skor}
                              //       </a>
                              //     </td>
                              //   </tr>
                              // ))
                            }
                            <tr>
                              {
                                //   <td align="center" style={{ fontSize: "1.5em" }}>
                                //   <br />
                                //   Total Skor
                                // </td>
                                // <td align="center">
                                //   <br />
                                //   <a
                                //     href="#!"
                                //     style={{
                                //       borderRadius: "10px",
                                //       background: "#00bfa5",
                                //       color: "white",
                                //       borderColor: "#00bfa5",
                                //       cursor: "default"
                                //     }}
                                //     className="button border"
                                //   >
                                //     {this.state.totalScore}
                                //   </a>
                                // </td>
                              }
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <br />
                        <br />
                        <a
                          href="#!"
                          onClick={e => {
                            e.preventDefault();

                            this.setState({
                              hide_pembahasan: !this.state.hide_pembahasan
                            });
                          }}
                          className="button border"
                        >
                          Pembahasan
                        </a>
                        <a
                          href="#!"
                          onClick={e => {
                            e.preventDefault();

                            localStorage.removeItem("pembahasan");

                            window.location.href = "/pilih-ujian";
                          }}
                          className="button border"
                        >
                          Selesai
                        </a>

                        <br />
                        <br />
                      </center>
                    </div>
                  </h4>
                  <table
                    hidden={this.state.hide_pembahasan}
                    className="basic-table"
                  >
                    <thead>
                      <tr>
                        <th style={{ background: "white", color: "#333" }}>
                          No
                        </th>
                        <th style={{ background: "white", color: "#333" }}>
                          Soal
                        </th>
                        <th style={{ background: "white", color: "#333" }}>
                          Jawaban
                        </th>
                        <th style={{ background: "white", color: "#333" }} />
                      </tr>
                    </thead>
                    <tbody>
                      {currentTodos.map((item, i) => (
                        <tr key={i}>
                          <td>{item.no}</td>
                          <td>{ReactHtmlParser(item.pertanyaan)}</td>
                          <td align="center">
                            <strong style={{ color: "#00bfa5" }}>
                              {item.jawaban}
                            </strong>
                          </td>
                          <td width="30%" align="right">
                            <a
                              href="#small-dialog"
                              className="button border popup-with-zoom-anim"
                              style={{ borderRadius: "5px" }}
                              onClick={() => this.setState({ detail: item })}
                            >
                              Lihat Pembahasan
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div
                  hidden={this.state.hide_pembahasan}
                  className="pagination-container margin-top-30 margin-bottom-0"
                >
                  <nav className="pagination">
                    <ul>
                      {pageNumbers.map(number => (
                        <li key={number}>
                          <a
                            href="#!"
                            id={number}
                            className={
                              currentPage === number ? "current-page" : ""
                            }
                            onClick={e => {
                              e.preventDefault();
                              //scroll up
                              document.body.scrollTop = 0;
                              document.documentElement.scrollTop = 0;

                              //update state
                              this.setState({
                                currentPage: Number(e.target.id)
                              });
                            }}
                          >
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div> //end div wrapper
    );
  }
}

export default scoring;
