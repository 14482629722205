//module
import React, { Component } from "react";
import axios from "axios";
import Edotensei from "edotensei";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get token
const session = JSON.parse(localStorage.getItem("token"));

class mulaiUjian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ujian: [],
      isLoaded: false
    };
  }

  componentDidMount() {
    //load scripts
    Edotensei.add(Scripts);
  }

  async componentWillMount() {
    try {
      var [ujian] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/landing-ujian/" + session.token)
      ]);

      this.setState({
        ujian: ujian.data.data,
        isLoaded: true
      });
    } catch (error) {
      alert("Ujian tidak tersedia");
      window.location.href = "/pilih-ujian";
    }
  }

  async handleLandingUjian(e) {
    e.preventDefault();

    try {
      const result = await axios.post(
        process.env.REACT_APP_API + "/set-timer",
        {
          token: session.token
        }
      );

      //check ujian
      if (!result.data.ok) {
        //alert
        alert(result.data.data);

        //redirect
        return (window.location.href = result.data.location);
      }

      //get the token
      const { token, displayName } = result.data;

      //save to local storage
      await localStorage.setItem(
        "token",
        JSON.stringify({ token, displayName })
      );

      //redirect
      window.location.href = "/mulai-ujian/ujian";
    } catch (error) {
      alert("Gagal mempersiapkan ujian, pastikan anda terhubung ke server!");

      //update state
      this.setState({ mempersiapkan: false });
    }
  }

  render() {
    //initial
    const { ujian } = this.state;

    return (
      <div style={{ background: "#f5f5f5" }}>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>
        <div hidden={!this.state.isLoaded}>
          {/** modal */}
          <div
            id="small-dialog"
            style={{ maxWidth: "400px", background: "rgb(207, 31, 73)" }}
            className="zoom-anim-dialog mfp-hide"
          >
            <div
              className="small-dialog-header"
              style={{ background: "transparent", marginBottom: "0" }}
            />
            <div className="message-reply margin-top-0">
              <center>
                <h3 style={{ color: "white" }}>Mulai Ujian Sekarang ?</h3>
                <br />
                <button
                  style={{ background: "white", color: "black" }}
                  className="button"
                  onClick={this.handleLandingUjian.bind(this)}
                >
                  Ya, Mulai Sekarang!
                </button>
              </center>
            </div>
          </div>

          {/** content */}
          <div className="container margin-top-30">
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{ background: "white" }}
                  className="dashboard-list-box margin-top-0"
                >
                  <h4 className="clearfix" style={{ position: "relative" }}>
                    <span style={{ fontSize: "2em" }}>Selamat datang</span>
                    <br />
                    <br />
                    <p style={{ fontSize: "1.2em" }}>
                      Keterangan {ujian.nama_ujian}
                    </p>
                    <br />
                    <br />
                    <a
                      href="#!"
                      style={{
                        cursor: "default",
                        borderRadius: "5px",
                        background: "transparent",
                        color: "#118def",
                        borderColor: "#118def"
                      }}
                      className="button border"
                    >
                      <span style={{ color: "#707070" }}>Waktu Pengerjaan</span>{" "}
                      {ujian.waktu} Menit
                    </a>
                    <br />
                    <br />
                    <p>{ujian.keterangan}</p>

                    <br />
                    <br />
                    <center>
                      <a
                        href="/pilih-ujian"
                        style={{ padding: "10px 50px", fontSize: "1em" }}
                        className="button border"
                      >
                        Kembali pilih ujian
                      </a>
                      <a
                        href="#small-dialog"
                        style={{ padding: "10px 50px", fontSize: "1em" }}
                        className="button border"
                        onClick={this.handleLandingUjian.bind(this)}
                      >
                        Mulai Sekarang
                      </a>
                    </center>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> //end div wrapper
    );
  }
}

export default mulaiUjian;
