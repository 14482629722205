//module
import React, { Component } from "react";
import Edotensei from "edotensei";
import propTypes from "prop-types";
import axios from "axios";
import "jodit";
import JoditEditor from "jodit-react";

//component
import Navbar from "./Include/dashboard-navbar";
import Sidebar from "./Include/dashboard-sidebar";

// Add the css styles...
import "jodit/build/jodit.min.css";

//scripts
const Scripts = [
  {
    src: "/scripts/jquery-2.2.0.min.js",
    rel: "preload"
  },
  {
    src: "/scripts/mmenu.min.js",
    defer: true
  },
  {
    src: "/scripts/chosen.min.js",
    defer: true
  },
  {
    src: "/scripts/slick.min.js",
    defer: true
  },
  {
    src: "/scripts/rangeslider.min.js",
    defer: true
  },
  {
    src: "/scripts/magnific-popup.min.js",
    defer: true
  },
  {
    src: "/scripts/waypoints.min.js",
    defer: true
  },
  {
    src: "/scripts/counterup.min.js",
    defer: true
  },
  {
    src: "/scripts/tooltips.min.js",
    defer: true
  },
  {
    src: "/scripts/custom.js",
    defer: true
  }
];

//get session
const session = JSON.parse(localStorage.getItem("token"));

class ujian extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id_ujian: "",
      ujian: [],
      ujians: [""],
      paket: [],
      isEdit: false,
      isLoaded: false,
      currentPage: 1,
      todosPerPage: 15,

      //tambah ujian
      nama_ujian: "",
      paket_pembelajaran: false,
      keterangan: "",
      petunjuk: "",
      durasi_ujian: "",
      standar_kelulusan: ""
    };
  }

  componentDidMount() {
    Edotensei.add(Scripts);
  }

  // async component
  async componentWillMount() {
    try {
      //get data
      let [result, result2] = await Promise.all([
        axios.get(process.env.REACT_APP_API + "/paket"),
        axios.get(process.env.REACT_APP_API + "/ujian")
      ]);

      //update state
      this.setState({
        paket: result.data.data,
        ujian: result2.data.data.reverse(),
        ujians: result2.data.data,
        isLoaded: true
      });
    } catch (error) {
      throw error;
    }
  }

  // handle search
  searchHandler(e) {
    //get paket
    var ujian = this.state.ujians;

    try {
      //update state
      this.setState({ currentPage: 1 });

      //filter
      ujian = ujian.filter(item => {
        //join
        var joinItem =
          item.nama_ujian + " " + item.waktu + " " + item.standar_kelulusan;
        //search
        return (
          joinItem.toLowerCase().search(e.target.value.toLowerCase()) !== -1
        );
      });

      //update state
      this.setState({ ujian });
    } catch (error) {
      alert(error);
    }
  }

  //handle tambah ujian
  async addUjianHandler(e) {
    e.preventDefault();

    if (!this.state.paket_pembelajaran)
      return alert("Pilih paket pembelajaran!");

    //confirm
    if (!window.confirm("Apakah data yang anda masukkan sudah benar?")) return;

    try {
      //request
      const result = await axios.post(process.env.REACT_APP_API + "/ujian", {
        token: session.token,
        nama_ujian: this.state.nama_ujian,
        id_paket: this.state.paket_pembelajaran,
        keterangan: this.state.keterangan,
        petunjuk: this.state.petunjuk,
        waktu: this.state.durasi_ujian,
        standar_kelulusan: this.state.standar_kelulusan
      });

      //close modal
      document.querySelector(".mfp-close").click();

      //alert
      alert(result.data.message);

      //re-get ujian
      this.componentWillMount();
    } catch (error) {
      throw error;
    }
  }

  async editUjianHandler(e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Simpan ujian ini?")) return;

    if (!this.state.paket_pembelajaran)
      return alert("Pilih paket pembelajaran!");

    //request
    const result = await axios({
      url: process.env.REACT_APP_API + "/ujian",
      method: "put",
      data: {
        token: session.token,
        id_ujian: this.state.id_ujian,
        id_paket: this.state.paket_pembelajaran,
        nama_ujian: this.state.nama_ujian,
        keterangan: this.state.keterangan,
        petunjuk: this.state.petunjuk,
        waktu: this.state.durasi_ujian,
        standar_kelulusan: this.state.standar_kelulusan
      }
    });

    //close modal
    document.querySelector(".mfp-close").click();

    //alet
    alert(result.data.message);

    //re-get ujian
    this.componentWillMount();
    try {
    } catch (error) {
      throw error;
    }
  }

  //handle delete
  async deleteHandler(id_ujian, e) {
    e.preventDefault();

    //confirm
    if (!window.confirm("Hapus ujian ini?")) return;

    try {
      await axios({
        url: process.env.REACT_APP_API + "/ujian",
        method: "delete",
        data: {
          token: session.token,
          id_ujian
        }
      });

      //re-get paket
      this.componentWillMount();
    } catch (error) {
      throw error;
    }
  }

  render() {
    //initial
    const { ujian, currentPage, todosPerPage } = this.state;

    // Logic for displaying current todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = ujian.slice(indexOfFirstTodo, indexOfLastTodo);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(ujian.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }
    return (
      <div>
        <div hidden={this.state.isLoaded}>
          <center>
            <div style={{ marginTop: "20%" }} className="lds-facebook">
              <div />
              <div />
              <div />
            </div>
          </center>
        </div>
        <div hidden={!this.state.isLoaded}>
          {/** modals */}
          <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
            <div className="small-dialog-header">
              <h3>{this.state.isEdit ? "Edit Ujian" : "Tambah Ujian"}</h3>
            </div>
            <div className="message-reply margin-top-0">
              <form
                onSubmit={
                  this.state.isEdit
                    ? this.editUjianHandler.bind(this)
                    : this.addUjianHandler.bind(this)
                }
              >
                <div className="row with-forms">
                  <div className="col-md-12">
                    <h5>Nama Ujian</h5>
                    <input
                      className="search-field"
                      type="text"
                      value={this.state.nama_ujian}
                      onChange={e => {
                        //update state
                        this.setState({ nama_ujian: e.target.value });
                      }}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <h5>Pilih Paket Pembelajaran</h5>
                    <select
                      value={this.state.paket_pembelajaran}
                      onChange={e => {
                        //update state
                        this.setState({ paket_pembelajaran: e.target.value });
                      }}
                      required
                    >
                      <option value="false" />
                      {this.state.paket.map((item, i) => (
                        <option value={item.id_paket} key={i}>
                          {item.nama_paket}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-12">
                    <h5>Keterangan Ujian</h5>
                    <textarea
                      value={this.state.keterangan}
                      onChange={e =>
                        this.setState({
                          keterangan: e.target.value
                        })
                      }
                    />
                  </div>

                  <div className="col-md-12">
                    <h5>Pentunjuk Pengerjaan Soal</h5>
                    <JoditEditor
                      // config={this.config2}
                      editorRef={this.setRef}
                      value={this.state.petunjuk}
                      onChange={value => this.setState({ petunjuk: value })}
                    />
                  </div>

                  <div className="col-md-6">
                    <h5>Durasi Ujian ( menit )</h5>
                    <input
                      min="0"
                      className="search-field"
                      type="number"
                      value={this.state.durasi_ujian}
                      onChange={e => {
                        //update state
                        this.setState({ durasi_ujian: e.target.value });
                      }}
                      required
                    />
                  </div>

                  <div className="col-md-6">
                    <h5>Standar Kelulusan</h5>
                    <input
                      min="0"
                      className="search-field"
                      type="number"
                      value={this.state.standar_kelulusan}
                      onChange={e => {
                        //update state
                        this.setState({ standar_kelulusan: e.target.value });
                      }}
                      required
                    />
                  </div>
                </div>

                <button className="button">
                  {" "}
                  {this.state.isEdit ? "Simpan" : "Tambahkan"}
                </button>
              </form>
            </div>
          </div>

          {/** navbar */}
          <Navbar level={this.props.userLevel} />

          <div id="dashboard">
            {/** sidebar */}
            <Sidebar level={this.props.userLevel} />

            {/** content / overview */}
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <div className="dashboard-list-box margin-top-0">
                    <h4 className="clearfix" style={{ position: "relative" }}>
                      <a
                        style={{ float: "right", borderRadius: "5px" }}
                        href="#small-dialog"
                        className="button popup-with-zoom-anim"
                        id="modal"
                        onClick={() => {
                          //update state
                          this.setState({
                            isEdit: false,
                            id_ujian: "",
                            nama_ujian: "",
                            paket_pembelajaran: "",
                            keterangan: "",
                            petunjuk: "",
                            durasi_ujian: "",
                            standar_kelulusan: ""
                          });
                        }}
                      >
                        <i className="sl sl-icon-plus" /> &nbsp;Tambah Ujian
                      </a>

                      <i
                        style={{ top: "56%", position: "absolute" }}
                        className="fa  fa-search"
                      />
                      <input
                        style={{
                          marginBottom: "0",
                          borderColor: "transparent",
                          // borderBottom: "1px solid #dbdbdb",
                          boxShadow: "none"
                        }}
                        type="text"
                        className="input-text"
                        name="email"
                        placeholder="cari berdasarkan nama ujian, waktu, atau standar kelulusan"
                        autoComplete="off"
                        onChange={this.searchHandler.bind(this)}
                      />
                    </h4>

                    <table className="basic-table">
                      <thead style={{ background: "white" }}>
                        <tr>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Nama Ujian
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Waktu
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Standar Kelulusan
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          >
                            Jumlah Soal
                          </th>
                          <th
                            style={{
                              background: "white",
                              color: "#333"
                            }}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {currentTodos.length > 0 ? (
                          currentTodos.map((item, i) => (
                            <tr key={i}>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="20%"
                              >
                                {item.nama_ujian}
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="20%"
                              >
                                {item.waktu} Menit
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="15%"
                              >
                                {item.standar_kelulusan}
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="10%"
                              >
                                {item.jumlah_soal
                                  ? item.jumlah_soal + " soal"
                                  : null}
                              </td>
                              <td
                                style={{
                                  borderBottom: "1px solid white"
                                }}
                                width="35%"
                                align="right"
                              >
                                <a
                                  href="#!"
                                  className="button border"
                                  onClick={e => {
                                    e.preventDefault();

                                    //open modal
                                    document.querySelector("#modal").click();

                                    //update state
                                    this.setState({
                                      id_ujian: item.id_ujian,
                                      nama_ujian: item.nama_ujian,
                                      paket_pembelajaran: item.id_paket,
                                      keterangan: item.keterangan,
                                      petunjuk: item.petunjuk,
                                      durasi_ujian: item.waktu,
                                      standar_kelulusan: item.standar_kelulusan,
                                      isEdit: true
                                    });
                                  }}
                                >
                                  Edit Ujian
                                </a>

                                <a
                                  href="#!"
                                  onClick={this.deleteHandler.bind(
                                    this,
                                    item.id_ujian
                                  )}
                                  className="button border"
                                >
                                  Hapus Ujian
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" align="center">
                              <h3>Ujian Tidak Ditemukan</h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="pagination-container margin-top-30 margin-bottom-0">
                <nav className="pagination">
                  <ul>
                    {pageNumbers.map(number => (
                      <li key={number}>
                        <a
                          href="#!"
                          id={number}
                          className={
                            currentPage === number ? "current-page" : ""
                          }
                          onClick={e => {
                            e.preventDefault();
                            //scroll up
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;

                            //update state
                            this.setState({
                              currentPage: Number(e.target.id)
                            });
                          }}
                        >
                          {number}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © PT. KERJA BARENGAN MENGINOVASI PENDIDIKAN, ALL RIGHTS RESERVED.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ujian.propTypes = {
  userLevel: propTypes.number.isRequired
};

export default ujian;
